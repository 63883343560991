/* 全局样式设置 */
@font-face
{
    font-family: SourceHanNormal;
    src: url('../fonts/SourceHanSansCN-Normal.ttf');
}


input:active,input:focus,button:active,button:focus{
    outline: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F8 inset;
    -webkit-text-fill-color: #000000;
}
html body{
    a,a:hover,a:visited,a:active{
        color: #000000;
    }
}

/* 初始设置 */
html{
    font-size:100px;
}
html body{
    font-family: SourceHanNormal, Helvetica, Arial, sans-serif;
    font-size:0.16rem;
    box-sizing: border-box;   
    background-color:#ffffff;
    color: #000000;
}
input,input::placeholder, textarea,textarea::placeholder{
    color: #000000;
    padding:0;
}

/* over hidden */
.over-hidden{
    overflow: hidden !important;
}

/* 省略号 */
.ellipsis{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
/* cursor-pointer */
.cursor-pointer{
    cursor: pointer !important;
}
/* disable btn */
.disable{
    opacity:0.5 !important;
}

.not-found{
    padding-top:2rem;
    text-align: center;
    color: gray;
}

.button{
    display: block;
    width:2.12rem;
    height:0.56rem;
    line-height:0.52rem;
    text-align: center;
    font-weight: 700;
    border: 0.02rem solid #000000;
    box-sizing: border-box;
    border-radius: 0.06rem;
    cursor: pointer;
    text-decoration: none;
}