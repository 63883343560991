.home-maininfo{
    max-width:12.8rem;
    margin:0.8rem auto 2rem;

    .dynamic-wraper{
        position: relative;
        font-size:1.4rem;
        font-weight: 700;
        height:1.8rem;
        text-align: center;
        margin:0 auto 0.2rem;

        .dynamic-text{
            position: absolute;
            height:100%;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            opacity:0;
            

            &.active{
                animation: textsFadeInOut 4s linear;
            }
        }
    }
    .maininfo-desc{
        font-size:0.16rem;
        line-height:0.24rem;
        text-align: center;
    }
    .maininfo-requestdemo{
        text-align: center;
        margin-top:0.8rem;

        .requestdemo-btn{
            margin:0 auto;
            width:3.2rem;
            background-color:#000000;
            color: #ffffff;
        }
        .requestdemo-text{
            margin-top:0.2rem;
            font-size:0.12rem;
        }
    }
}


@keyframes textsFadeInOut {
    0% {
        opacity: 0
    }

    25% {
        opacity: 1
    }

    75% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}